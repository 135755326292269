import logo from './logo.svg';
import './App.css';
import MeuMenu from './MeuMenu.js';
import Preco from './Preco.js';
import Hugo from './Hugo.js';

function App() {
  return (
    <div className="App">
     <MeuMenu/>
     <Preco/>
     <Hugo/>
    </div>
  );
}

export default App;
